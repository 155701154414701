
.swiper {
  width: 100%;
  padding: 20px 0;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 200px;
  height: 200px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  border-radius: 20px;
}
