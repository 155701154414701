/* src/components/AudioPlayer.css */
.audio-player {
    position: relative;
  }
  
  .play-pause-container {
    position: relative;
    display: inline-block;
  }
  
  .play-pause-icon {
    width: 2rem;
    height: 2rem;
    transition: opacity 0.8s ease-in-out; /* Transition for opacity */
  }
  
  .playing {
    animation: blink 2s infinite; /* Add the blink animation for playing state */
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  