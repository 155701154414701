.timecounter {
    display: flex;
    justify-content: space-around;
    font-family: sans-serif;
    text-align: center;
    padding-top: 40px;
    gap: 10px;
}

.time {
    font-size: 14px;
}

.dimension{
font-size: 9px;
}